import { WppToastContainer } from '@platform-ui-kit/components-library-react'
import React, { useRef } from 'react'

import styles from './Toast.module.scss'

/**
 * Create toast element
 */
const Toast: React.FC = (): React.ReactElement => {
  const childRef = useRef(null)
  return <WppToastContainer className={styles.toastContainer} id="wpp-toast" maxToastsToDisplay={5} ref={childRef} />
}

export default Toast
