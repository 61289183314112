import { WppStep } from '@platform-ui-kit/components-library-react'
import React from 'react'

import styles from './Category.module.scss'
import ICategory from '../../../interfaces/ICategory'

interface IProps {
  /** Category */
  category: ICategory
}

/**
 * Category component
 * @param {object} props Component props
 * @param {ICategory} props.category Category
 */
const Category: React.FC<IProps> = ({ category }: IProps): React.ReactElement => {
  return (
    <WppStep error={!category.isValid} className={styles.customStyleStep} key={category.id}>
      <p slot="label">{category.name}</p>
    </WppStep>
  )
}

export default Category
