// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rJnqt{display:flex}.d90xP{flex-direction:column}.b82vy{flex-direction:row}.kViCH{display:flex;margin-top:.5rem;flex-direction:column}.t6Jn5{display:flex;flex-direction:column}.t6Jn5 .B8xD5{display:flex}.Yy8Bw{margin-bottom:.5rem;margin-right:0;height:auto !important}.JoM31{margin-bottom:0;margin-right:10;height:auto !important}`, "",{"version":3,"sources":["webpack://./src/app/components/form/formField/formFieldRadio/FormFieldRadio.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CAEF,OACE,qBAAA,CAGF,OACE,kBAAA,CAGF,OACE,YAAA,CACA,gBAAA,CACA,qBAAA,CAEF,OACE,YAAA,CACA,qBAAA,CAEA,cACE,YAAA,CAIJ,OACE,mBAAA,CACA,cAAA,CACA,sBAAA,CAGF,OACE,eAAA,CACA,eAAA,CACA,sBAAA","sourcesContent":[".radioGroup {\n  display: flex;\n}\n.displayRadioGroupVertical {\n  flex-direction: column;\n}\n\n.displayRadioGroupHorizontal {\n  flex-direction: row;\n}\n\n.container {\n  display: flex;\n  margin-top: 0.5rem;\n  flex-direction: column;\n}\n.radioContainer {\n  display: flex;\n  flex-direction: column;\n\n  .radio {\n    display: flex;\n  }\n}\n\n.displayRadioVertical {\n  margin-bottom: 0.5rem;\n  margin-right: 0;\n  height: auto !important;\n}\n\n.displayRadioHorizontal {\n  margin-bottom: 0;\n  margin-right: 10;\n  height: auto !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radioGroup": `rJnqt`,
	"displayRadioGroupVertical": `d90xP`,
	"displayRadioGroupHorizontal": `b82vy`,
	"container": `kViCH`,
	"radioContainer": `t6Jn5`,
	"radio": `B8xD5`,
	"displayRadioVertical": `Yy8Bw`,
	"displayRadioHorizontal": `JoM31`
};
export default ___CSS_LOADER_EXPORT___;
