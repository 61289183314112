import _ from 'lodash'
import React from 'react'

import FormFieldBuilder from './formFieldBuilder'
import { OrderBy } from '../../../../config/enums'
import IField from '../../../../interfaces/field/IField'
import IFieldChangeEvent from '../../../../interfaces/IFieldChangeEvent'
import IForm from '../../../../interfaces/IForm'

interface IFormBuilderProps {
  /** IForm object */
  form: IForm
  /** handle input change event. It has InputFieldChangeEvent object which have IField, and current field value*/
  handleChange: (event: IFieldChangeEvent) => void
}

/**
 * Build form
 * @param {object} props Component props
 * @param {IForm} props.form Form object
 * @param {function} props.handleChange handle input field change event
 */
const FormBuilder: React.FC<IFormBuilderProps> = ({ form, handleChange }: IFormBuilderProps): React.ReactElement => {
  return (
    <>
      {_.orderBy(form.fields, ['displayOrder'], OrderBy.ASC).map((field: IField) => {
        return <FormFieldBuilder key={field.id} field={field} handleChange={handleChange} />
      })}
    </>
  )
}

export default FormBuilder
