import axios, { AxiosInstance, RawAxiosRequestHeaders } from 'axios'
import axiosRetry from 'axios-retry'

const instance: AxiosInstance = axios.create({
  baseURL: 'http://localhost:9050/',
})

// Axios retry in case of failed API i.e network error
axiosRetry(instance, {
  retryDelay: (retryCount: number) => retryCount * 2000,
  retries: 2,
})

const buildRequestHeader = (accessToken: string): RawAxiosRequestHeaders => ({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${accessToken}`,
})

// GET Request
const get = <T>(url: string, accessToken: string): Promise<T> => {
  return instance.get(url, {
    headers: buildRequestHeader(accessToken),
  })
}

// POST Request
const post = <T>(url: string, data: any, accessToken: string): Promise<T> => {
  return instance.post(url, data, {
    headers: buildRequestHeader(accessToken),
  })
}

// PUT Request
const put = <T>(url: string, data: any, accessToken: string): Promise<T> => {
  return instance.put(url, data, {
    headers: buildRequestHeader(accessToken),
  })
}

const AxiosService = {
  get,
  post,
  put,
  instance,
}

export default AxiosService
