// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.km78l{background:#fff;box-shadow:0px 0px 1px rgba(52,58,63,.1),0px 1px 5px rgba(52,58,63,.1);border-radius:8px;padding:24px 32px}.d_RNT{font-size:1.25rem;font-weight:600}.TGQCa{margin-top:1rem;line-height:1.375rem;font-size:.875rem}.vitcJ::part(message){color:#4d5358}`, "",{"version":3,"sources":["webpack://./src/app/components/form/Form.module.scss"],"names":[],"mappings":"AAAA,OACE,eAAA,CAKA,sEAAA,CACA,iBAAA,CACA,iBAAA,CAGF,OACE,iBAAA,CACA,eAAA,CAEF,OACE,eAAA,CACA,oBAAA,CACA,iBAAA,CAIA,sBACE,aAAA","sourcesContent":[".container {\n  background: #ffffff;\n  /* Drop Shadow/s\n    \n      Card: Idle\n      */\n  box-shadow: 0px 0px 1px rgba(52, 58, 63, 0.1), 0px 1px 5px rgba(52, 58, 63, 0.1);\n  border-radius: 8px;\n  padding: 24px 32px;\n}\n\n.name {\n  font-size: 1.25rem;\n  font-weight: 600;\n}\n.description {\n  margin-top: 1rem;\n  line-height: 1.375rem;\n  font-size: 0.875rem;\n}\n\n.customStyleDefaultMessage {\n  &::part(message) {\n    color: #4d5358;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `km78l`,
	"name": `d_RNT`,
	"description": `TGQCa`,
	"customStyleDefaultMessage": `vitcJ`
};
export default ___CSS_LOADER_EXPORT___;
