import { WppCheckbox } from '@platform-ui-kit/components-library-react'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import styles from './FormFieldCheckbox.module.scss'
import { LabelType, Orientation } from '../../../../../config/enums'
import IField from '../../../../../interfaces/field/IField'
import IFieldChangeEvent from '../../../../../interfaces/IFieldChangeEvent'
import IOption from '../../../../../interfaces/IOption'
import FormFieldChildren from '../formFieldChildren'
import FormFieldLabel from '../formFieldLabel'
import FormFieldMessage from '../formFieldMessage'

interface IFormFieldCheckboxProps {
  /** IField interface */
  field: IField
  /** handle input change event. It has IFieldChangeEvent interface which have IField interface, and current field value*/
  handleChange: (event: IFieldChangeEvent) => void
}

/**
 * Create checkbox field
 * @param {object} props
 * @param {IField} props.field
 * @param {Function} props.handleChange
 */
const FormFieldCheckbox: React.FC<IFormFieldCheckboxProps> = ({
  field,
  handleChange,
}: IFormFieldCheckboxProps): React.ReactElement => {
  const { disabled, value, fieldConfig } = field

  const ORIENTATION = _.isUndefined(fieldConfig.orientation) ? Orientation.VERTICAL : fieldConfig.orientation

  const OPTIONS = fieldConfig.options || []
  const Values = _.isArray(value) ? value : []
  const Options = Values.map((option: IOption) => option.id)

  const onChange = (option: IOption): void => {
    const Value = !_.isEqual(Options.indexOf(option.id), -1)
      ? Values.filter((val: IOption) => !_.isEqual(val.id, option.id))
      : _.uniqBy([...Values, option], 'id')

    handleChange({
      field,
      value: Value,
    })
  }

  return (
    <>
      <FormFieldLabel
        text={fieldConfig.text}
        helpModal={fieldConfig.helpModal}
        tooltip={fieldConfig.tooltip}
        disabled={field.disabled}
      />
      <div
        className={classNames(
          {
            [styles.displayVertical]: _.isEqual(ORIENTATION, Orientation.VERTICAL),
            [styles.displayHorizontal]: _.isEqual(ORIENTATION, Orientation.HORIZONTAL),
          },
          styles.container,
        )}
      >
        {OPTIONS.map((option: IOption, index: number) => (
          <div key={option.id}>
            <div>
              <WppCheckbox
                key={option.id}
                style={{
                  marginTop: !_.isEqual(index, 0) && _.isEqual(ORIENTATION, Orientation.VERTICAL) ? 10 : 0,
                  marginRight: _.isEqual(ORIENTATION, Orientation.HORIZONTAL) ? 10 : 0,
                }}
                id={`checkbox-${field.id}-${index + 1}`}
                disabled={disabled}
                required={true}
                checked={!_.isEqual(Options.indexOf(option.id), -1)}
                labelConfig={{ text: !_.isUndefined(option.helpModal) ? '' : option.label }}
                onWppChange={() => onChange(option)}
              />
              <FormFieldLabel
                type={LabelType.BODY}
                text={option.label}
                helpModal={option.helpModal}
                disabled={field.disabled}
                handleChange={() => {
                  handleChange({
                    field,
                    value: option.id,
                  })
                }}
              />
            </div>
            <FormFieldChildren
              field={{
                ...field,
                value,
              }}
              optionValue={option.id}
              handleChange={handleChange}
            />
          </div>
        ))}
      </div>
      <FormFieldMessage field={field} />
    </>
  )
}

export default FormFieldCheckbox
