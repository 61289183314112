import { TextareaInputChangeEventDetail } from '@platform-ui-kit/components-library'
import { WppTextareaInputCustomEvent } from '@platform-ui-kit/components-library/dist/types/components'
import { WppTextareaInput } from '@platform-ui-kit/components-library-react'
import _ from 'lodash'
import React from 'react'

import IField from '../../../../../interfaces/field/IField'
import IFieldChangeEvent from '../../../../../interfaces/IFieldChangeEvent'
import styles from '../../Form.module.scss'
import FormFieldLabel from '../formFieldLabel'

interface IFormFieldTextareaProps {
  /** IField interface */
  field: IField
  /** handle input change event. It has IFieldChangeEvent interface which have IField interface, and current field value*/
  handleChange: (event: IFieldChangeEvent) => void
}

/**
 * Create textarea field
 * @param {object} props
 * @param {IField} props.field
 * @param {Function} props.handleChange
 */
const FormFieldTextarea: React.FC<IFormFieldTextareaProps> = ({
  field,
  handleChange,
}: IFormFieldTextareaProps): React.ReactElement => {
  const { disabled, fieldConfig, value, messageType, errorMessage } = field
  const { placeholder, charactersLimit } = fieldConfig

  let charactersLimitProps = {}
  if (!_.isUndefined(charactersLimit)) {
    charactersLimitProps = {
      charactersLimit,
      warningThreshold: charactersLimit - 10,
    }
  }

  return (
    <>
      <FormFieldLabel
        text={fieldConfig.text}
        helpModal={fieldConfig.helpModal}
        tooltip={fieldConfig.tooltip}
        disabled={field.disabled}
      />
      <div>
        <WppTextareaInput
          slot="form-element"
          size="s"
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          id={`textarea-${field.id}`}
          messageType={messageType}
          message={_.isEqual(messageType, 'error') ? errorMessage : fieldConfig.help}
          className={_.isEqual(messageType, 'error') ? '' : styles.customStyleDefaultMessage}
          onWppChange={(event: WppTextareaInputCustomEvent<TextareaInputChangeEventDetail>) =>
            handleChange({
              field,
              value: event.detail.value,
            })
          }
          {...charactersLimitProps}
        />
      </div>
    </>
  )
}

export default FormFieldTextarea
