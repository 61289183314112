import React from 'react'

import { FieldType } from '../../../../../config/enums'
import IField from '../../../../../interfaces/field/IField'
import IFieldChangeEvent from '../../../../../interfaces/IFieldChangeEvent'
import FormFieldAutoComplete from '../../formField/formFieldAutoComplete'
import FormFieldCheckbox from '../../formField/formFieldCheckbox'
import FormFieldCounter from '../../formField/formFieldCounter'
import FormFieldRadio from '../../formField/formFieldRadio'
import FormFieldTextarea from '../../formField/formFieldTextarea'
import FormFieldTextInput from '../../formField/formFieldTextInput'

interface IFormFieldBuilderProps {
  /** IField object */
  field: IField
  /** handle input change event. It has InputFieldChangeEvent object which have IField, and current field value*/
  handleChange: (event: IFieldChangeEvent) => void
}

/**
 * Build form field
 * @param {object} props Component props
 * @param {IField} props.field Form object
 * @param {function} props.handleChange handle input field change event
 */
const FormFieldBuilder: React.FC<IFormFieldBuilderProps> = (props: IFormFieldBuilderProps): React.ReactElement => {
  let field = <></>

  if (props.field.isHidden) {
    return field
  }

  switch (props.field.type) {
    // Text Input Field
    case FieldType.TEXT_INPUT:
      field = <FormFieldTextInput {...props} />
      break
    // Radio Field
    case FieldType.RADIO:
      field = <FormFieldRadio {...props} />
      break
    // Checkbox Field
    case FieldType.CHECKBOX:
      field = <FormFieldCheckbox {...props} />
      break
    // Counter Field
    case FieldType.COUNTER:
      field = <FormFieldCounter {...props} />
      break
    // Textarea Field
    case FieldType.TEXT_AREA:
      field = <FormFieldTextarea {...props} />
      break
    // Autocomplete Field
    case FieldType.AUTOCOMPLETE:
      field = <FormFieldAutoComplete {...props} />
      break
  }
  return field
}

export default FormFieldBuilder
