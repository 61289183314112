import _ from 'lodash'

import { FieldType } from '../../config/enums'
import IField from '../../interfaces/field/IField'
import SharedHelper from '../SharedHelper'

export default class FormFieldValidation {
  private sharedHelper: SharedHelper
  constructor() {
    this.sharedHelper = new SharedHelper()
  }
  /**
   * Validate a field
   * @param {IField} field
   * @param {any} value Updated value of the field
   * @returns {boolean}
   */
  validate(field: IField, value: any): boolean {
    let isValid = true

    if (_.isEqual(field.type, FieldType.TEXT_AREA)) {
      isValid = this.sharedHelper.validateCharacterLimit(field, value)
    }
    return isValid
  }
}
