import { RadioGroupChangeEvent } from '@platform-ui-kit/components-library'
import { WppRadioGroupCustomEvent } from '@platform-ui-kit/components-library/dist/types/components'
import { WppRadioGroup, WppRadio } from '@platform-ui-kit/components-library-react'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import styles from './FormFieldRadio.module.scss'
import { LabelType, Orientation } from '../../../../../config/enums'
import IField from '../../../../../interfaces/field/IField'
import IFieldChangeEvent from '../../../../../interfaces/IFieldChangeEvent'
import IOption from '../../../../../interfaces/IOption'
import FormFieldChildren from '../formFieldChildren'
import FormFieldDescription from '../formFieldDescription'
import FormFieldLabel from '../formFieldLabel'
import FormFieldMessage from '../formFieldMessage'

interface IFormFieldRadioProps {
  /** IField interface */
  field: IField
  /** handle input change event. It has InputFieldChangeEvent interface which have IField interface, and current field value*/
  handleChange: (event: IFieldChangeEvent) => void
}

/**
 * Create radio field
 * @param {object} props
 * @param {IField} props.field
 * @param {Function} props.handleChange
 */
const FormFieldRadio: React.FC<IFormFieldRadioProps> = ({
  field,
  handleChange,
}: IFormFieldRadioProps): React.ReactElement => {
  const { disabled, fieldConfig, value } = field
  const { text, options } = fieldConfig

  const ORIENTATION = _.isUndefined(fieldConfig.orientation) ? Orientation.VERTICAL : fieldConfig.orientation
  const OPTIONS = options || []

  return (
    <>
      <FormFieldLabel
        text={fieldConfig.text}
        helpModal={fieldConfig.helpModal}
        tooltip={fieldConfig.tooltip}
        disabled={field.disabled}
      />
      <WppRadioGroup
        className={classNames(
          {
            [styles.displayRadioGroupVertical]: _.isEqual(ORIENTATION, Orientation.VERTICAL),
            [styles.displayRadioGroupHorizontal]: _.isEqual(ORIENTATION, Orientation.HORIZONTAL),
          },
          styles.radioGroup,
        )}
        onWppChange={(event: WppRadioGroupCustomEvent<RadioGroupChangeEvent>) => {
          handleChange({
            field,
            value: event.detail.value,
          })
        }}
        id={`radio-group-${field.id}`}
      >
        {OPTIONS.map((option: IOption) => (
          <div className={styles.container} key={option.id}>
            <div className={styles.radioContainer}>
              <div className={styles.radio}>
                <WppRadio
                  className={classNames({
                    [styles.displayRadioVertical]: _.isEqual(ORIENTATION, Orientation.VERTICAL),
                    [styles.displayRadioHorizontal]: _.isEqual(ORIENTATION, Orientation.HORIZONTAL),
                  })}
                  required={true}
                  name={text}
                  disabled={disabled}
                  value={option.id}
                  checked={_.isEqual(option.id, value)}
                  labelConfig={{
                    text: !_.isUndefined(option.helpModal) ? '' : option.label,
                  }}
                />
                <FormFieldLabel
                  type={LabelType.BODY}
                  text={option.label}
                  helpModal={option.helpModal}
                  disabled={field.disabled}
                  handleChange={() => {
                    handleChange({
                      field,
                      value: option.id,
                    })
                  }}
                />
              </div>
              <FormFieldDescription
                field={{
                  ...field,
                  value,
                }}
                option={option}
              />
            </div>
            <FormFieldChildren
              field={{
                ...field,
                value,
              }}
              optionValue={option.id}
              handleChange={handleChange}
            />
          </div>
        ))}
      </WppRadioGroup>

      <FormFieldMessage field={field} />
    </>
  )
}

export default FormFieldRadio
