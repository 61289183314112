import { LoadMoreHandler } from '@platform-ui-kit/components-library'
import { WppAutocomplete, WppAvatar, WppListItem } from '@platform-ui-kit/components-library-react'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'

import styles from './FormFieldAutoComplete.module.scss'
import SelectedValues from './SelectedValues'
import IField from '../../../../../interfaces/field/IField'
import IFieldChangeEvent from '../../../../../interfaces/IFieldChangeEvent'
import IOption from '../../../../../interfaces/IOption'
import AxiosService from '../../../../../lib/AxiosService'
import formStyles from '../../Form.module.scss'
import FormFieldLabel from '../formFieldLabel'

interface IFormFieldAutocompleteProps {
  /** IField interface */
  field: IField
  /** handle input change event. It has InputFieldChangeEvent interface which have IField interface, and current element value*/
  handleChange: (event: IFieldChangeEvent) => void
}

const generateInfiniteResults = async (
  infiniteSearchValueTrimmed: any,
  page: number,
  apiURL: string = '',
  accessToken: string = '',
) => {
  if (_.isEmpty(accessToken) || _.isEmpty(apiURL)) return
  const Result: any = await AxiosService.get(
    `${apiURL}?filter[search]=${infiniteSearchValueTrimmed}&offset=${page}&limit=10&orderBy=asc`,
    accessToken,
  )

  const Response = _.isArray(Result.data.data) ? Result.data.data : []

  return Response.map((user: any) => {
    return {
      id: user.id,
      label: `${user.firstname} ${user.lastname}`,
      subLabel: user.email,
      avatarUrl: user.avatarUrl,
    }
  })
}

/**
 * Create autocomplete element
 * @param {object} props
 * @param {IField} props.field
 * @param {Function} props.handleChange
 */
const FormFieldAutocomplete: React.FC<IFormFieldAutocompleteProps> = ({
  field,
  handleChange,
}: IFormFieldAutocompleteProps): React.ReactElement => {
  const { disabled, value, messageType, errorMessage, fieldConfig } = field
  const { placeholder, isMulti, accessToken, apiUrl } = fieldConfig

  const Value: any = _.isNull(value) ? [] : value

  const isInfiniteFirstLoadSkipped = useRef(false)
  const infiniteLoadMoreTimer = useRef<ReturnType<typeof setTimeout>>()
  const [infiniteSearchPage, setInfiniteSearchPage] = useState(0)
  const [isSearchingInfinite, setIsSearchingInfinite] = useState(false)
  const [infiniteSearchValue, setInfiniteSearchValue] = useState('')
  const [infiniteSearchResults, setInfiniteSearchResults] = useState<any[]>([])

  const infiniteSearchValueTrimmed = infiniteSearchValue.trim()
  const infiniteSearchLoadMore: LoadMoreHandler = () =>
    new Promise(resolve => {
      const page = infiniteSearchResults.length ? infiniteSearchPage + 1 : infiniteSearchPage

      infiniteLoadMoreTimer.current = setTimeout(async () => {
        infiniteLoadMoreTimer.current = undefined
        const Users = await generateInfiniteResults(infiniteSearchValueTrimmed, page, apiUrl, accessToken)
        setInfiniteSearchResults(current => [...current, ...Users])
        setInfiniteSearchPage(page)

        resolve()
      }, 500)
    })

  // Load infinite options based on search
  useEffect(() => {
    if (_.isEmpty(infiniteSearchValueTrimmed)) return

    if (isInfiniteFirstLoadSkipped.current) {
      if (infiniteLoadMoreTimer.current) {
        clearTimeout(infiniteLoadMoreTimer.current)
        infiniteLoadMoreTimer.current = undefined
      }

      setIsSearchingInfinite(true)

      const timer = setTimeout(async () => {
        const Users = await generateInfiniteResults(infiniteSearchValueTrimmed, 0, apiUrl, accessToken)
        setInfiniteSearchResults(() => [...Users])
        setInfiniteSearchPage(0)
        setIsSearchingInfinite(false)
      }, 500)

      return () => clearTimeout(timer)
    } else {
      isInfiniteFirstLoadSkipped.current = true
    }
  }, [infiniteSearchValueTrimmed, apiUrl, accessToken])

  return (
    <>
      <div className={styles.wrapper} data-testid="autocompletes">
        <div className={styles.item}>
          <FormFieldLabel
            text={fieldConfig.text}
            helpModal={fieldConfig.helpModal}
            tooltip={fieldConfig.tooltip}
            disabled={field.disabled}
          />
          <WppAutocomplete
            name="custom-option-labels"
            infinite
            // infiniteLastPage={isInfiniteLastPage(infiniteSearchValueTrimmed, infiniteSearchPage)}
            loading={isSearchingInfinite}
            placeholder={placeholder}
            value={Value}
            onWppChange={e => {
              handleChange({
                field,
                value: e.detail.value,
              })
            }}
            onWppSearchValueChange={e => setInfiniteSearchValue(e.detail)}
            id={`autocomplete-${field.id}`}
            loadMore={infiniteSearchLoadMore}
            disabled={disabled}
            multiple={isMulti}
            simpleSearch
            type="extended"
            className={_.isEqual(messageType, 'error') ? '' : formStyles.customStyleDefaultMessage}
            message={_.isEqual(messageType, 'error') ? errorMessage : fieldConfig.help}
            messageType={messageType}
          >
            {infiniteSearchResults.map((option: IOption) => (
              <WppListItem key={option.id} value={option} label={option.label}>
                <div className={styles.secondary} slot="label">
                  {option.label}
                </div>
                <div className={styles.secondary} slot="caption">
                  {option.subLabel}
                </div>
                <WppAvatar size="s" src={option.avatarUrl} slot="left" name={option.label} />
              </WppListItem>
            ))}
            <SelectedValues
              values={Value}
              onCloseClick={(selectedValue: any) => {
                const UpdatedValue = Value.filter((option: IOption) => !_.isEqual(option.id, selectedValue))
                handleChange({
                  field,
                  value: UpdatedValue,
                })
              }}
            />
          </WppAutocomplete>
        </div>
      </div>
    </>
  )
}

export default FormFieldAutocomplete
