import { WppTypography } from '@platform-ui-kit/components-library-react'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import styles from './FormFieldLabel.module.scss'
import { LabelType } from '../../../../../config/enums'
import IHelpModal from '../../../../../interfaces/field/IHelpModal'
import FormFieldTooltip from '../formFieldTooltip'

interface IFormFieldLabelProps {
  /** Label title */
  text: string
  /** Help Modal */
  helpModal?: IHelpModal
  /** If element is disabled */
  disabled: boolean
  /** handle input change event */
  handleChange?: () => void
  /** Tooltip */
  tooltip?: string
  /** Field description */
  description?: string
  /** label type - heading or body */
  type?: LabelType
}

/**
 * Create label for the field
 * @param {object} props
 * @param {IField} props.field
 */
const FormFieldLabel: React.FC<IFormFieldLabelProps> = (props: IFormFieldLabelProps): React.ReactElement => {
  const { text, disabled, helpModal, tooltip, description, type, handleChange } = {
    description: '',
    type: LabelType.HEADING,
    tooltip: '',
    handleChange: null,
    ...props,
  }

  if (_.isEmpty(text)) {
    return <div className={styles.emptyLabel} />
  }

  if (_.isEqual(type, 'body') && _.isUndefined(helpModal)) {
    return <></>
  }

  return (
    <>
      <div
        className={classNames(styles.container, {
          [styles.bodyContainer]: _.isEqual(type, 'body'),
        })}
      >
        <WppTypography
          className={classNames(
            {
              [styles.disabled]: disabled,
            },
            styles.label,
          )}
          onClick={event => {
            if (_.isEqual(event.target, event.currentTarget) && !disabled && !_.isNull(handleChange)) {
              handleChange()
            }
          }}
          type={_.isEqual(type, 'heading') ? 's-strong' : 's-body'}
        >
          {text}
          <div className={styles.tooltip}>
            <FormFieldTooltip helpModal={helpModal} tooltip={tooltip} />
          </div>
        </WppTypography>
      </div>
      {!_.isEmpty(description) && (
        <WppTypography className={styles.description} type="s-body">
          {description}
        </WppTypography>
      )}
    </>
  )
}

export default FormFieldLabel
