import { WppButton } from '@platform-ui-kit/components-library-react'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import styles from './Action.module.scss'
import { AppAction, StepAction } from '../../../config/enums'
import ICategory from '../../../interfaces/ICategory'

interface IActionProps {
  /** ICategory interface */
  categories: ICategory[]
  /** Handle app Submit/Save Progress/Cancel event*/
  handleAppAction: (appAction: string) => void
  /** Handle Next/Previous step event*/
  handleStep: (stepNumber: number, stepAction: StepAction) => void
  /** Active step*/
  activeStep: number
  /** Is valid for submit*/
  isValidSubmit: boolean
  /** if user has editor access*/
  isAppEditor: boolean
}

/**
 * App action
 * @param {object} props
 * @param {ICategory[]} props.categories
 * @param {Function} props.handleFormAction
 * @param {Function} props.handleStep
 * @param {number} props.activeStep
 * @param {boolean} props.isValidSubmit
 */
const Action: React.FC<IActionProps> = ({
  categories,
  handleAppAction,
  handleStep,
  activeStep,
  isValidSubmit,
  isAppEditor,
}: IActionProps): React.ReactElement => {
  let btnNextOrSubmit = (
    <WppButton
      id="btn-next"
      onClick={() => handleStep(activeStep + 1, StepAction.NEXT)}
      className={styles.btnNextSubmit}
      disabled={!categories[activeStep - 1].isValid || _.isEqual(categories.length, activeStep)}
    >
      Next
    </WppButton>
  )

  // If all steps are filled
  if (_.isEqual(categories.length, activeStep) && isAppEditor) {
    btnNextOrSubmit = (
      <WppButton
        disabled={!isValidSubmit}
        id="btn-submit"
        onClick={() => handleAppAction(AppAction.SAVE_AND_EXIT)}
        className={styles.btnNextSubmit}
      >
        Save and Exit
      </WppButton>
    )
  }

  return (
    <>
      {isAppEditor && (
        <WppButton
          id="btn-save-progress"
          onClick={() => handleAppAction(AppAction.SAVE_PROGRESS)}
          disabled={!categories[activeStep - 1].isValid}
          className={classNames(styles.btnSaveProgress, {
            [styles.disabled]: !categories[activeStep - 1].isValid,
          })}
        >
          Save Progress
        </WppButton>
      )}

      <WppButton id="btn-cancel" onClick={() => handleAppAction(AppAction.CANCEL)} variant="secondary">
        Cancel
      </WppButton>
      <WppButton
        id="btn-back"
        onClick={() => handleStep(activeStep - 1, StepAction.PREVIOUS)}
        disabled={_.isEqual(activeStep, 1)}
      >
        Previous
      </WppButton>
      {btnNextOrSubmit}
    </>
  )
}

export default Action
