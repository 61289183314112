import _ from 'lodash'

import { FieldType } from '../../config/enums'
import IField from '../../interfaces/field/IField'
import IApp from '../../interfaces/IApp'
import ICategory from '../../interfaces/ICategory'
import IFieldChangeEvent from '../../interfaces/IFieldChangeEvent'
import IOption from '../../interfaces/IOption'
import FormFieldHelper from '../formField/FormFieldHelper'
import FormFieldValidation from '../formField/FormFieldValidation'

export default class FormValidation {
  private formFieldValidation: FormFieldValidation
  private formFieldHelper: FormFieldHelper

  /**
   *  Creates an instance of FormValidation
   */
  constructor() {
    this.formFieldValidation = new FormFieldValidation()
    this.formFieldHelper = new FormFieldHelper()
  }

  /**
   * Validate app
   * @param {IApp} app
   * @returns boolean
   */
  validateApp(app: IApp): IApp {
    let isAppValid = true

    const Categories = app.categories.map((category: ICategory) => {
      let isValidCategory = true

      const Fields: IField[] = category.form.fields.map((field: IField) => {
        const IsValidField = this.formFieldValidation.validate(field, field.value)
        isValidCategory = isValidCategory && IsValidField

        return {
          ...field,
          touched: false,
          isValid: IsValidField,
        }
      })

      isAppValid = isAppValid && isValidCategory

      return {
        ...category,
        form: {
          ...category.form,
          fields: Fields,
        },
        isValid: true,
      }
    })

    return {
      ...app,
      categories: Categories,
    }
  }

  /**
   * Validate category forms
   * @param {ICategory[]} categories
   * @returns boolean
   */
  validateCategories(categories: ICategory[]): ICategory[] {
    return categories.map((category: ICategory) => {
      let isValid = true

      category.form.fields.forEach((field: IField) => {
        isValid = isValid && field.isValid

        field.children.forEach((childField: IField) => {
          isValid = isValid && childField.isValid
        })
      })

      return {
        ...category,
        isValid,
      }
    })
  }

  isChildVisible(parentField: IField, childField: IField) {
    if (_.isEqual(parentField.type, FieldType.CHECKBOX)) {
      const Values = parentField.value.map((option: IOption) => option.id)
      return !_.isEqual(Values.indexOf(childField.visibleOnValue), -1)
    }
    return _.isEqual(childField.visibleOnValue, parentField.value)
  }

  validateChild(parentField: IField, childField: IField): IField {
    let isValid = this.formFieldValidation.validate(childField, childField.value)

    isValid = this.isChildVisible(parentField, childField) ? isValid : true

    return {
      ...childField,
      isValid,
      isHidden: !this.isChildVisible(parentField, childField),
    }
  }

  validateChildren(field: IField): IField[] {
    return field.children.map((childField: IField) => {
      return this.validateChild(field, childField)
    })
  }

  validateDependentField(categories: ICategory[], event: IFieldChangeEvent): ICategory[] {
    return categories.map((category: ICategory) => {
      return {
        ...category,
        form: {
          ...category.form,
          fields: category.form.fields.map((field: IField) => {
            if (_.isEqual(field.id, event.field.id)) {
              if (!_.isUndefined(field.visibleOn)) {
                const ParentField = this.formFieldHelper.findFieldById(categories, field.visibleOn)
                field = _.isNull(ParentField) ? field : this.validateChild(ParentField, field)
              }

              field.children = this.validateChildren(field)
              return field
            }
            return field
          }),
        },
      }
    })
  }

  /**
   * Validate category forms
   * @param {ICategory[]} categories
   * @returns boolean
   */
  validateForms(categories: ICategory[]): boolean {
    let isValid = true

    categories.forEach((category: ICategory) => {
      isValid = isValid && category.isValid
    })

    return isValid
  }
}
