// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.z2140::part(typography){font-size:.875rem}.xERW8{display:flex;align-items:center;padding-bottom:.5rem;padding-top:1.5rem}.xERW8 .Tc871{position:absolute;display:inline}.GpZIc{padding:0;margin-left:.5rem;cursor:pointer}.mTohb{height:1rem}.yB7LR{margin-bottom:1.5rem}.M5z0i{color:#a9a9a9;cursor:default}`, "",{"version":3,"sources":["webpack://./src/app/components/form/formField/formFieldLabel/FormFieldLabel.module.scss"],"names":[],"mappings":"AACE,yBACE,iBAAA,CAIJ,OACE,YAAA,CACA,kBAAA,CACA,oBAAA,CACA,kBAAA,CAEA,cACE,iBAAA,CACA,cAAA,CAIJ,OACE,SAAA,CACA,iBAAA,CACA,cAAA,CAGF,OACE,WAAA,CAEF,OACE,oBAAA,CAGF,OACE,aAAA,CACA,cAAA","sourcesContent":[".label {\n  &::part(typography) {\n    font-size: 0.875rem;\n  }\n}\n\n.container {\n  display: flex;\n  align-items: center;\n  padding-bottom: 0.5rem;\n  padding-top: 1.5rem;\n\n  .tooltip {\n    position: absolute;\n    display: inline;\n  }\n}\n\n.bodyContainer {\n  padding: 0;\n  margin-left: 0.5rem;\n  cursor: pointer;\n}\n\n.emptyLabel {\n  height: 1rem;\n}\n.description {\n  margin-bottom: 1.5rem;\n}\n\n.disabled {\n  color: #a9a9a9;\n  cursor: default;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `z2140`,
	"container": `xERW8`,
	"tooltip": `Tc871`,
	"bodyContainer": `GpZIc`,
	"emptyLabel": `mTohb`,
	"description": `yB7LR`,
	"disabled": `M5z0i`
};
export default ___CSS_LOADER_EXPORT___;
