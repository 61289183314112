import { WppSideModal } from '@platform-ui-kit/components-library-react'
import React from 'react'

import styles from './Help.module.scss'
import './styles.scss'

interface ISideModalProps {
  /** Modal title */
  title: string
  /** Modal content */
  content: string
  /** If modal is open */
  isModalOpen: boolean
  /** handle modal events */
  handleModalAction: () => void
}

/**
 * Help component
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.content
 * @param {boolean} props.isModalOpen
 * @param {Function} props.handleModalAction
 */
const Help: React.FC<ISideModalProps> = ({
  title,
  content,
  isModalOpen,
  handleModalAction,
}: ISideModalProps): React.ReactElement => (
  <WppSideModal
    className={styles.modal}
    open={isModalOpen}
    onWppSideModalClose={handleModalAction}
    onWppSideModalOpen={handleModalAction}
  >
    <h3 slot="header">{title}</h3>
    <div slot="body">
      <div
        className="modalBody"
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    </div>
  </WppSideModal>
)

export default Help
