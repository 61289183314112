import { WppGrid, WppTypography } from '@platform-ui-kit/components-library-react'
import React from 'react'

import styles from './Form.module.scss'
import FormBuilder from './formBuilder/FormBuilder'
import ICategory from '../../../interfaces/ICategory'
import IFieldChangeEvent from '../../../interfaces/IFieldChangeEvent'

interface IFormProps {
  /** ICategory interface */
  category: ICategory
  /** handle input change event. It has IFieldChangeEvent interface which have IField interface, and current field value*/
  handleChange: (event: IFieldChangeEvent) => void
}

/**
 * Display form
 * @param {object} props
 * @param {ICategory} props.category
 */
const Form: React.FC<IFormProps> = ({ category, handleChange }: IFormProps): React.ReactElement => {
  return (
    <div className={styles.container}>
      <WppGrid fullWidth container>
        <WppGrid item xl={10} xxl={12} sm={24} md={16} all={24}>
          <WppTypography className={styles.name} tag="h1" type="xl-heading">
            {category.name}
          </WppTypography>
          <p className={styles.description}>{category.description}</p>
          <FormBuilder form={category.form} handleChange={handleChange} />
        </WppGrid>
      </WppGrid>
    </div>
  )
}

export default Form
