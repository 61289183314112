import _ from 'lodash'

import { APP_EDITOR_PERMISSION, APP_VIEWER_PERMISSION } from '../../config/enums'
import IPermission from '../../interfaces/IPermission'

export default class PermissionHelper {
  validateUserPermission(permissions: IPermission[]) {
    let isAppEditor = false
    let isAuthorizedUser = false
    permissions.forEach((permission: IPermission) => {
      permission.permissions.forEach((perm: string) => {
        if (_.isEqual(perm, APP_EDITOR_PERMISSION)) {
          isAppEditor = true
          isAuthorizedUser = true
        }

        if (_.isEqual(perm, APP_VIEWER_PERMISSION)) {
          isAuthorizedUser = true
        }
      })
    })

    return {
      isAppEditor,
      isAuthorizedUser,
    }
  }
}
