import _ from 'lodash'

import FormFieldValidation from './FormFieldValidation'
import { FieldType } from '../../config/enums'
import IField from '../../interfaces/field/IField'
import ICategory from '../../interfaces/ICategory'
import IFieldChangeEvent from '../../interfaces/IFieldChangeEvent'
import IOption from '../../interfaces/IOption'
import SharedHelper from '../SharedHelper'

export default class FormFieldHelper {
  private formfieldValidation: FormFieldValidation
  private sharedHelper: SharedHelper
  constructor() {
    this.formfieldValidation = new FormFieldValidation()
    this.sharedHelper = new SharedHelper()
  }

  /**
   * Compare field prev and updated value
   * @param {string} tooltipText
   * @returns {boolean}
   */
  isValidChange(event: IFieldChangeEvent): boolean {
    const { field, value } = event
    let isValidChange = false
    switch (field.type) {
      case FieldType.COUNTER:
      case FieldType.RADIO:
      case FieldType.TEXT_INPUT:
      case FieldType.TEXT_AREA: {
        isValidChange = !_.isEqual(value, field.value)
        break
      }
      case FieldType.CHECKBOX:
      case FieldType.AUTOCOMPLETE: {
        isValidChange = !_.isEmpty(this.sharedHelper.isDiffArray(value, field.value))
        break
      }
    }
    return isValidChange
  }

  /**
   * Get default value
   * @param {IField} field
   * @returns {any}
   */
  getDefaultValue(field: IField): any {
    let value = null
    switch (field.type) {
      case FieldType.TEXT_INPUT:
      case FieldType.TEXT_AREA: {
        value = ''
        break
      }
      case FieldType.CHECKBOX:
      case FieldType.AUTOCOMPLETE: {
        value = []
        break
      }
    }
    return value
  }

  /**
   * Update an field
   * @param {IField} field
   * @param {InputFieldChangeEvent} event
   * @returns {IField}
   */
  updateField(field: IField, event: IFieldChangeEvent): IField {
    if (_.isEqual(field.id, event.field.id)) {
      const IsValid = this.formfieldValidation.validate(field, event.value)

      const Field: IField = {
        ...field,
        value: event.value,
        touched: true,
        isValid: IsValid,
      }

      if (IsValid) {
        const { messageType, errorMessage, ...rest } = Field
        return rest
      }

      return {
        ...Field,
        messageType: 'error',
      }
    }

    return {
      ...field,
      children: field.children.map((childField: IField) => {
        return this.updateField(childField, event)
      }),
    }
  }

  /**
   * Get field children
   * @param {IField} field
   * @param {any} value
   * @returns {IField[]}
   */

  getChildren(field: IField, value: any): IField[] {
    if (_.isEqual(field.type, FieldType.CHECKBOX)) {
      const Values = value.map((option: IOption) => option.id)
      return field.children.filter((childField: IField) => {
        return !_.isEqual(Values.indexOf(childField.visibleOnValue), -1)
      })
    }

    return field.children.filter((childField: IField) => _.isEqual(childField.visibleOnValue, value))
  }

  /**
   * Get field by id
   * @param {ICategory[]} field
   * @param {string} fieldId
   * @param {IField | any} value
   * @returns {IField[]}
   */
  findFieldById(categories: ICategory[], fieldId: string): IField | null {
    let field = null
    categories.forEach((category: ICategory) => {
      category.form.fields.forEach((fieldParam: IField) => {
        if (_.isEqual(fieldParam.id, fieldId)) {
          field = fieldParam
        }
      })
    })
    return field
  }
}
