import { CounterChangeEventDetail } from '@platform-ui-kit/components-library'
import { WppCounterCustomEvent } from '@platform-ui-kit/components-library/dist/types/components'
import { WppCounter } from '@platform-ui-kit/components-library-react'
import _ from 'lodash'
import React from 'react'

import styles from './FormFieldCounter.module.scss'
import IField from '../../../../../interfaces/field/IField'
import IFieldChangeEvent from '../../../../../interfaces/IFieldChangeEvent'
import formStyles from '../../Form.module.scss'
import FormFieldLabel from '../formFieldLabel'

interface IFormFieldCounterProps {
  /** IField interface */
  field: IField
  /** handle input change event. It has IFieldChangeEvent interface which have IField interface, and current field value*/
  handleChange: (event: IFieldChangeEvent) => void
}

/**
 * Create counter field
 * @param {object} props
 * @param {IField} props.field
 * @param {Function} props.handleChange
 */
const FormFieldCounter: React.FC<IFormFieldCounterProps> = ({
  field,
  handleChange,
}: IFormFieldCounterProps): React.ReactElement => {
  const { disabled, messageType, errorMessage, fieldConfig, value } = field
  const Value: number = !_.isNumber(value) ? 1 : value

  return (
    <>
      <FormFieldLabel
        text={fieldConfig.text}
        helpModal={fieldConfig.helpModal}
        tooltip={fieldConfig.tooltip}
        disabled={field.disabled}
      />
      <div className={styles.container}>
        <WppCounter
          value={Value}
          onWppChange={(event: WppCounterCustomEvent<CounterChangeEventDetail>) =>
            handleChange({
              field,
              value: event.detail.value,
            })
          }
          id={`counter-${field.id}`}
          disabled={disabled}
          format={{
            searchValue: /(.)(?=(\d{3})+$)/g,
            replaceValue: '$1 ',
          }}
          messageType={messageType}
          maxMessageLength={400}
          message={_.isEqual(messageType, 'error') ? errorMessage : fieldConfig.help}
          className={_.isEqual(messageType, 'error') ? '' : formStyles.customStyleDefaultMessage}
        />
      </div>
    </>
  )
}

export default FormFieldCounter
