import _ from 'lodash'

import IField from 'interfaces/field/IField'

export default class SharedHelper {
  /**
   * If the value is an array
   * @param {any} value Input value
   * @returns {boolean}
   */
  isValidArrayValue(value: any): boolean {
    const Value = value || []
    return _.isArray(Value) && !_.isEmpty(Value)
  }

  /**
   * If the value is not empty
   * @param {any} value
   * @returns {boolean}
   */
  isValidTextValue(value: any): boolean {
    return !_.isEmpty((value || '').toString().trim())
  }

  /**
   * If the value is not empty
   * @param {any} value
   * @returns {boolean}
   */
  isValidValue(value: any): boolean {
    if (_.isNumber(value)) {
      return true
    }
    return this.isValidTextValue(value)
  }

  /**
   * If the value is not empty
   * @param {any} value
   * @returns {boolean}
   */
  validateCharacterLimit(field: IField, value: any): boolean {
    if (_.isNull(value)) {
      return true
    }

    if (_.isUndefined(field.fieldConfig.charactersLimit)) {
      return true
    }

    return _.lte(value.length, field.fieldConfig.charactersLimit)
  }

  /**
   * Difference of two array
   * @param {array} x
   * @param {array} y
   * @returns {boolean}
   */
  isDiffArray = (x: any, y: any): any => {
    return _.xorWith(x, y, _.isEqual)
  }

  /**
   * Check and return valid array
   * @param {any[]} array
   * @returns {any[]}
   */
  getValidArray = (array: any): any[] => {
    return _.isArray(array) ? array : []
  }
}
