import { WppTypography } from '@platform-ui-kit/components-library-react'
import _ from 'lodash'
import React from 'react'

import styles from './FormFieldDescription.module.scss'
import IField from '../../../../../interfaces/field/IField'
import IOption from '../../../../../interfaces/IOption'

interface IFormFieldCounterProps {
  /** IField interface */
  field: IField
  /** IOption interface */
  option: IOption
}

/**
 * Create counter field
 * @param {object} props
 * @param {IField} props.field
 * @param {IOption} props.option
 */
const FormFieldDescription: React.FC<IFormFieldCounterProps> = ({
  option,
  field,
}: IFormFieldCounterProps): React.ReactElement => {
  if (_.isUndefined(option.description) || !_.isEqual(option.id, field.value)) {
    return <></>
  }

  return (
    <WppTypography className={styles.description} type="s-body">
      {option.description}
    </WppTypography>
  )
}

export default FormFieldDescription
