import { WppTypography, WppGrid, WppStepper } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import _ from 'lodash'
import React, { useState, useEffect } from 'react'

import styles from './Questionnaire.module.scss'
import Action from '../app/components/action'
import Category from '../app/components/category'
import Form from '../app/components/form'
import Spinner from '../app/components/spinner/Spinner'
import Toast from '../app/components/toast/Toast'
import { AppAction, OrderBy, StepAction } from '../config/enums'
import { AppData } from '../data/AppData'
import useToast from '../hooks/useToast'
import IApp from '../interfaces/IApp'
import ICategory from '../interfaces/ICategory'
import IFieldChangeEvent from '../interfaces/IFieldChangeEvent'
import FormHelper from '../utils/form/FormHelper'
import FormFieldHelper from '../utils/formField/FormFieldHelper'

/**
 * Questionnaire app
 */
const Questionnaire: React.FC = (): React.ReactElement => {
  const { osContext, osApi } = useOs()
  const [activeStep, setStep] = useState(1)
  const [app, setApp] = useState<IApp | null>(null)
  const { showToast } = useToast()

  /**
   * Handle Stepper change
   * @param {number} stepNumber
   */
  const handleStep = (stepNumber: number, stepAction: StepAction) => {
    if (_.isNull(app)) {
      return
    }

    if (_.gt(stepNumber, app.categories.length)) {
      return
    }

    if (_.isEqual(stepAction, StepAction.NEXT) && app.isAppEditor) {
      onSaveProgress(app.categories[activeStep - 1])
    }
    setStep(stepNumber)
  }

  /**
   * Save form data
   * @param {ICategory} category
   */
  const onSaveProgress = async (category: ICategory): Promise<void> => {
    const formHelper = new FormHelper()
    console.log(formHelper.onSaveProgress(category, osContext.userDetails.id))
  }

  /**
   * Handle app actions i.e Submit, Save Progress, Cancel
   * @param {string} appAction
   */
  const handleAppAction = async (appAction: string): Promise<void> => {
    if (_.isNull(app)) {
      return
    }

    try {
      const formHelper = new FormHelper()

      switch (appAction) {
        case AppAction.SAVE_AND_EXIT: {
          const RequestData = formHelper.onSaveAndExit(app.categories, osContext.userDetails.id)
          console.log(RequestData)

          showToast({
            header: 'Success',
            message: 'The data have been submitted',
            type: 'success',
            duration: 5000,
          })
          break
        }
        case AppAction.SAVE_PROGRESS: {
          await onSaveProgress(app.categories[activeStep - 1])
          console.log('Handle Save Progress')
          showToast({
            header: 'Success',
            message: 'The data have been submitted',
            type: 'success',
            duration: 5000,
          })
          break
        }
        default:
          break
      }
    } catch {
      showToast({
        header: 'Oops! Something went wrong',
        message:
          'Your data has not been saved. Please try again later or contact customer support if the problem persists.',
        type: 'error',
        duration: 5000,
      })
    }
  }

  /**
   * Handle form field change
   * @param {IFieldChangeEvent} event
   */
  const handleChange = (event: IFieldChangeEvent): void => {
    if (_.isNull(app)) return

    const formFieldHelper = new FormFieldHelper()

    if (!formFieldHelper.isValidChange(event)) return

    const formHelper = new FormHelper()
    const UpdatedApp = formHelper.updateApp(app, event)

    setApp(UpdatedApp)
  }

  useEffect(() => {
    const formHelper = new FormHelper()
    const UpdatedApp = formHelper.getApp(AppData, osContext.permissions, osApi.getAccessToken())

    setApp(UpdatedApp)
  }, [osApi, osContext.permissions])

  if (!_.isNull(app) && !app.isAuthorizedUser) {
    return <h1>Unauthorized</h1>
  }

  return (
    <>
      {!_.isNull(app) ? (
        <WppGrid container className={styles.layout} rowSpacing={2}>
          <WppGrid item all={24} className={styles.layoutHeader}>
            <WppTypography tag="h1" type="xl-heading">
              {app.appName}
            </WppTypography>
            <div className={styles.actions}>
              <Action
                categories={app.categories}
                activeStep={activeStep}
                handleAppAction={handleAppAction}
                handleStep={handleStep}
                isValidSubmit={app.isValid}
                isAppEditor={app.isAppEditor}
              />
            </div>
          </WppGrid>
          <WppGrid className={styles.layoutCategories} item all={4}>
            <WppStepper completedSteps={activeStep - 1} activeStep={activeStep}>
              {_.orderBy(app.categories, ['displayOrder'], OrderBy.ASC).map((category: ICategory) => (
                <Category key={category.id} category={category} />
              ))}
            </WppStepper>
          </WppGrid>
          <WppGrid item all={20}>
            <div>
              <Form category={app.categories[activeStep - 1]} handleChange={handleChange} />
            </div>
          </WppGrid>
        </WppGrid>
      ) : (
        <Spinner />
      )}
      <Toast />
    </>
  )
}

export default Questionnaire
