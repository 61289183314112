import _ from 'lodash'

import FormValidation from './FormValidation'
import PermissionHelper from './PermissionHelper'
import { OrderBy } from '../../config/enums'
import IField from '../../interfaces/field/IField'
import IApp from '../../interfaces/IApp'
import ICategory from '../../interfaces/ICategory'
import IFieldChangeEvent from '../../interfaces/IFieldChangeEvent'
import IPermission from '../../interfaces/IPermission'
import IResponse from '../../interfaces/IResponse'
import FormFieldHelper from '../formField/FormFieldHelper'

export default class FormHelper {
  private formFieldHelper: FormFieldHelper
  private formValidation: FormValidation
  private permissionHelper: PermissionHelper

  /**
   *  Creates an instance of FormHelper
   */
  constructor() {
    this.formFieldHelper = new FormFieldHelper()
    this.formValidation = new FormValidation()
    this.permissionHelper = new PermissionHelper()
  }

  getOrderedData(data: any[]): any[] {
    return _.orderBy(data, ['displayOrder'], OrderBy.ASC)
  }

  defaultFieldConfiguration(field: IField, IsAppEditor: boolean, accessToken: string): IField {
    return {
      ...field,
      fieldConfig: {
        ...field.fieldConfig,
        text: field.fieldConfig.text,
        help: field.fieldConfig.help,
        accessToken,
      },
      children: field.children.map((childField: IField) =>
        this.defaultFieldConfiguration(childField, IsAppEditor, accessToken),
      ),
      isValid: true,
      isHidden: false,
      disabled: !IsAppEditor,
    }
  }

  /**
   * Get app
   * @param {ICategory[]} categories
   * @param {InputFieldChangeEvent} event
   * @returns {ICategory[]}
   */
  getApp(app: IApp, permissions: IPermission[], accessToken: string): IApp {
    const { isAppEditor, isAuthorizedUser } = this.permissionHelper.validateUserPermission(permissions)
    return {
      ...app,
      appName: app.appName,
      isValid: true,
      isAppEditor,
      isAuthorizedUser,
      categories: this.updateDependentFields(
        this.getOrderedData(
          app.categories.map((category: ICategory) => ({
            ...category,
            name: category.name,
            description: category.description,
            form: {
              ...category.form,
              fields: this.getOrderedData(
                category.form.fields.map((field: IField) =>
                  this.defaultFieldConfiguration(field, isAppEditor, accessToken),
                ),
              ),
            },
          })),
        ),
      ),
    }
  }

  /**
   * Update app
   * @param {ICategory[]} categories
   * @param {InputFieldChangeEvent} event
   * @returns {ICategory[]}
   */
  updateApp(app: IApp, event: IFieldChangeEvent): IApp {
    let categories: ICategory[] = this.updateForms(app.categories, event)
    categories = this.formValidation.validateDependentField(categories, event)
    categories = this.formValidation.validateCategories(categories)
    const IsValidApp: boolean = this.formValidation.validateForms(categories)

    return {
      ...app,
      categories,
      isValid: IsValidApp,
    }
  }

  /**
   * Update forms
   * @param {ICategory[]} categories
   * @param {InputFieldChangeEvent} event
   * @returns {ICategory[]}
   */
  updateForms(categories: ICategory[], event: IFieldChangeEvent): ICategory[] {
    return this.updateDependentFields(
      categories.map((category: ICategory) => ({
        ...category,
        form: {
          ...category.form,
          fields: category.form.fields.map((field: IField) => this.formFieldHelper.updateField(field, event)),
        },
      })),
    )
  }

  /**
   * Update dependent field
   * @param {ICategory[]} categories
   * @returns {ICategory[]}
   */
  updateDependentFields(categories: ICategory[]) {
    return categories.map((category: ICategory) => ({
      ...category,
      form: {
        ...category.form,
        fields: category.form.fields.map((field: IField) => {
          if (!_.isUndefined(field.visibleOn) && !_.isUndefined(field.visibleOnValue)) {
            const Field: IField | null = this.formFieldHelper.findFieldById(categories, field.visibleOn)
            return {
              ...field,
              isHidden: !(!_.isNull(Field) && _.isEqual(Field.value, field.visibleOnValue)),
            }
          }
          return field
        }),
      },
    }))
  }

  /**
   * Build Save Request Data
   * @param {IField} field
   * @param {string} userId
   * @param {string} fieldValue
   * @returns {IResponse}
   */
  buildSaveRequestData(field: IField, userId: string, fieldValue: any): IResponse {
    return {
      form_field_id: field.id,
      value: fieldValue,
      user_id: userId,
    }
  }

  /**
   * Save Form Progress
   * @param {ICategory} category
   * @param {string} userId
   * @returns {IResponse[]}
   */
  onSaveProgress(category: ICategory, userId: string): IResponse[] {
    const Fields: IResponse[] = []
    category.form.fields.forEach((field: IField) => {
      const Value = field.isHidden ? this.formFieldHelper.getDefaultValue(field) : field.value
      Fields.push(this.buildSaveRequestData(field, userId, JSON.stringify(Value)))

      field.children.forEach((childField: IField) => {
        const ChildValue = childField.isHidden ? this.formFieldHelper.getDefaultValue(childField) : childField.value
        Fields.push(this.buildSaveRequestData(childField, userId, JSON.stringify(ChildValue)))
      })
    })
    return Fields
  }

  /**
   * Save & Exit
   * @param {ICategory[]} category
   * @param {string} userId
   * @returns {IResponse[]}
   */
  onSaveAndExit(categories: ICategory[], userId: string): IResponse[] {
    let fields: IResponse[] = []

    categories.forEach((category: ICategory) => {
      fields = _.concat(fields, this.onSaveProgress(category, userId))
    })
    return fields
  }
}
