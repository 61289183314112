import { InputChangeEventDetail } from '@platform-ui-kit/components-library'
import { WppInputCustomEvent } from '@platform-ui-kit/components-library/dist/types/components'
import { WppInput } from '@platform-ui-kit/components-library-react'
import _ from 'lodash'
import React from 'react'

import IField from '../../../../../interfaces/field/IField'
import IFieldChangeEvent from '../../../../../interfaces/IFieldChangeEvent'
import styles from '../../Form.module.scss'
import FormFieldLabel from '../formFieldLabel'

interface IFormFieldTextInputProps {
  /** IField interface */
  field: IField
  /** handle input change event. It has InputFieldChangeEvent interface which have IField interface, and current field value*/
  handleChange: (event: IFieldChangeEvent) => void
}

/**
 * Create text input field
 * @param {object} props
 * @param {IField} props.field
 * @param {Function} props.handleChange
 */
const FormFieldTextInput: React.FC<IFormFieldTextInputProps> = ({
  field,
  handleChange,
}: IFormFieldTextInputProps): React.ReactElement => {
  const { disabled, messageType, errorMessage, fieldConfig, value } = field

  const { placeholder } = fieldConfig

  return (
    <>
      <FormFieldLabel
        text={fieldConfig.text}
        helpModal={fieldConfig.helpModal}
        tooltip={fieldConfig.tooltip}
        disabled={field.disabled}
      />
      <WppInput
        placeholder={placeholder}
        value={value}
        onWppChange={(event: WppInputCustomEvent<InputChangeEventDetail>) =>
          handleChange({
            field,
            value: event.detail.value,
          })
        }
        id={`textinput-${field.id}`}
        disabled={disabled}
        messageType={messageType}
        message={_.isEqual(messageType, 'error') ? errorMessage : fieldConfig.help}
        className={_.isEqual(messageType, 'error') ? '' : styles.customStyleDefaultMessage}
      />
    </>
  )
}

export default FormFieldTextInput
