import { WppInlineMessage } from '@platform-ui-kit/components-library-react'
import _ from 'lodash'
import React from 'react'

import styles from './FormFieldMessage.module.scss'
import IField from '../../../../../interfaces/field/IField'

interface IFormFieldMessageProps {
  /** IField interface */
  field: IField
}

/**
 * Create label for an field
 * @param {object} props
 * @param {IField} props.field
 */
const FormFieldMessage: React.FC<IFormFieldMessageProps> = ({ field }: IFormFieldMessageProps): React.ReactElement => {
  const { messageType, errorMessage, fieldConfig } = field

  return (
    <WppInlineMessage
      size="s"
      type={messageType}
      showTooltipFrom={200}
      className={_.isEqual(messageType, 'error') ? styles.errorMessage : styles.customStyleDefaultMessage}
      message={_.isEqual(messageType, 'error') ? errorMessage : fieldConfig.help}
    />
  )
}
export default FormFieldMessage
