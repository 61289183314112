// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JePTf{display:flex;align-items:center;gap:.625rem}.wf9RN .NRZnQ{margin-top:8px}.wf9RN .vWwnC{display:flex;align-items:center;justify-content:space-between;width:100%;margin:2rem 0 1.5rem 0}`, "",{"version":3,"sources":["webpack://./src/pages/Questionnaire.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,kBAAA,CACA,WAAA,CAIA,cACE,cAAA,CAEF,cACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,UAAA,CACA,sBAAA","sourcesContent":[".actions {\n  display: flex;\n  align-items: center;\n  gap: 0.625rem;\n}\n\n.layout {\n  .layoutCategories {\n    margin-top: 8px;\n  }\n  .layoutHeader {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 100%;\n    margin: 2rem 0 1.5rem 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": `JePTf`,
	"layout": `wf9RN`,
	"layoutCategories": `NRZnQ`,
	"layoutHeader": `vWwnC`
};
export default ___CSS_LOADER_EXPORT___;
