export enum FieldType {
  COUNTER = 'COUNTER',
  RADIO = 'RADIO',
  CHECKBOX = 'CHECKBOX',
  TEXT_INPUT = 'TEXT_INPUT',
  TEXT_AREA = 'TEXT_AREA',
  AUTOCOMPLETE = 'AUTOCOMPLETE',
}

// Order by options
export enum OrderBy {
  ASC = 'asc',
  DESC = 'desc',
}

// App actions
export enum AppAction {
  SAVE_PROGRESS = 'save_progress',
  SAVE_AND_EXIT = 'save_and_exit',
  CANCEL = 'cancel',
}

// Stepper action types
export enum StepAction {
  PREVIOUS = 'previous',
  NEXT = 'next',
}

// This is used in Checkbox and Radio button
export enum Orientation {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

export enum LabelType {
  HEADING = 'heading',
  BODY = 'body',
}

export const APP_VIEWER_PERMISSION = 'PREREQUISITES_APP_FORM_VIEW'
export const APP_EDITOR_PERMISSION = 'PREREQUISITES_APP_FORM_COMPLETE'
